import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';
import { Reward, RewardType } from '@wix/ambassador-loyalty-v1-reward/types';
import { CouponNames } from '@wix/loyalty-coupon-names';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { createCurrencyFormatter } from '../createCurrencyFormatter';
import { AppInstallStatus } from '../getAppInstallStatus';

interface CreateRewardDescriptionBuilderParams {
  flowAPI: ControllerFlowAPI;
  loyaltyProgram: LoyaltyProgram;
  couponNames: CouponNames;
  appInstallStatus: AppInstallStatus;
}

export interface BuildRewardDescriptionParams {
  reward: Reward;
  configIndex: number;
}

export const createRewardDescriptionBuilder =
  ({ loyaltyProgram, couponNames, flowAPI, appInstallStatus }: CreateRewardDescriptionBuilderParams) =>
  ({ reward, configIndex }: BuildRewardDescriptionParams) => {
    let result: string;
    const pointsName = loyaltyProgram.pointDefinition?.customName;
    const { t } = flowAPI.translations;
    const formatCurrency = createCurrencyFormatter(flowAPI);
    const getCouponSubtitle = couponNames.getCouponSubtitle;

    if (reward.type === RewardType.DISCOUNT_AMOUNT) {
      const { costInPoints, amount } = reward.discountAmount?.configsByTier?.[configIndex]!;
      const useNewDiscountRewardDescription = !appInstallStatus.isOldRestaurantsAppInstalled;

      const newTranslationKey = pointsName
        ? 'app.reward-description.discount-custom'
        : 'app.reward-description.discount';

      const oldTranslationKey = pointsName
        ? 'app.reward-description.discount-amount-custom'
        : 'app.reward-description.discount-amount';

      result = t(useNewDiscountRewardDescription ? newTranslationKey : oldTranslationKey, {
        pointsName,
        pointsRequired: costInPoints,
        discountAmount: formatCurrency(parseFloat(amount ?? '0')),
      });
    } else if (reward.type === RewardType.COUPON_REWARD) {
      const couponReward = reward.couponReward!;
      const configsByTier =
        couponReward.percentage?.configsByTier ??
        couponReward.fixedAmount?.configsByTier ??
        couponReward.freeShipping?.configsByTier;

      const config = configsByTier?.[configIndex]!;
      const discountLabel = getCouponSubtitle({ couponReward, config });
      const pointsKey = pointsName ? 'app.reward-description.coupon-any-custom' : 'app.reward-description.coupon-any';
      const pointsLabel = t(pointsKey, {
        pointsName,
        pointsRequired: config.costInPoints,
      });

      result = `${pointsLabel} ${discountLabel}`;
    } else {
      result = t('app.reward-description.empty');
    }

    return result;
  };
